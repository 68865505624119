import { getProps } from "@/lib/component"

export const pushNextRoute = (pathData, isFirstRequest, isLoggedIn) => {
  const { tracking } = getProps(pathData.props, "object") ?? {}
  const financiers = tracking?.financier ?? []

  const nextRoute = {
    event: "nextRoute",
    path: pathData.path,
    campaign: tracking?.campaign ?? "",
    financier: financiers.join(", "),
    isFirstRequest: isFirstRequest,
    isLoggedin: isLoggedIn,
    author: tracking?.author ?? "",
    internalContact: tracking?.internalContact ?? ""
  }

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(nextRoute)
}
