import { useEffect, useState } from "react"
import { initGoogleTagManager } from "@/lib/google-tag-manager"
import { useAppContext } from "@/lib/contexts/appContext"
import fetchPathData from "@/lib/api/fetchPathData"
import { CartProvider } from "@/components/woocommerce/cartStore"
import { Layout } from "@/components/base/layout"
import { useMember } from "@/lib/hooks/useMember"
import { pushNextRoute } from "@/lib/helpers/nextRoute"
import { useTracking } from "@/lib/hooks/useTracking"
import Head from "@/lib/head"

const Index = ({ pathData }) => {
  const { meta, resetAppContext } = useAppContext()
  const { isLoggedIn } = useMember()
  const [firstRequest, setFirstRequest] = useState(true)

  useEffect(() => {
    const { gtmContainerId } = meta
    if (gtmContainerId) {
      initGoogleTagManager(gtmContainerId)
    }
    setFirstRequest(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    resetAppContext()
    pushNextRoute(pathData, firstRequest, isLoggedIn)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathData])

  useTracking()

  return (
    <>
      <Head />
      <CartProvider>
        <Layout />
      </CartProvider>
    </>
  )
}

export async function getStaticPaths() {
  return {
    paths: [],
    fallback: "blocking"
  }
}

export async function getStaticProps(context) {
  let slugs = context.params?.slug

  if (slugs && slugs[0] === "index") {
    slugs = []
  }

  const path = slugs?.length ? "/" + slugs.join("/") + "/" : "/"

  if (0 === path.indexOf("/bra-miljoval/")) {
    const redirectURL =
      process.env.BMV_APP_URL + path.replace("/bra-miljoval", "")

    return {
      redirect: {
        destination: redirectURL,
        permanent: true
      },
      revalidate: 1
    }
  }

  if (context.preview) {
    return {
      redirect: {
        destination: "/api/stopPreviewSession" + path
      },
      revalidate: 1
    }
  }

  try {
    const pathData = await fetchPathData({
      path,
      isPreview: context.preview && path === context?.previewData?.path
    })

    if (!pathData) {
      throw new Error("API response error")
    }

    if (pathData.status === 404) {
      return {
        notFound: true,
        revalidate: 60
      }
    }

    if (pathData.redirect) {
      return {
        redirect: {
          destination: pathData.path,
          permanent: [301, 308].includes(pathData.status)
        },
        revalidate: 60
      }
    }

    return {
      props: { pathData },
      revalidate: 60
    }
  } catch (error) {
    throw new Error("Internal error")
  }
}

export default Index
